.selected {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.page {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
}

.break {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
}

.previous {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
}

.next{
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
}

.disabled {
    opacity: 0.25;
    cursor: not-allowed;
    user-select: none;
}