body {
  margin: 0;
  background-color: #f9f9f9;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  font-size:16px;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgb(187, 187, 187) !important;
}

.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgb(148, 148, 148) !important;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgb(161, 161, 161) !important;
}
.ReactModal__Overlay {
  z-index: 2;
}